<script setup>
import { ref, onMounted } from 'vue';

// Chaînes encodées en entités HTML


// Fonction pour décoder les entités HTML
function decodeHTMLEntities(text) {
      const txt = document.createElement('textarea');
      txt.innerHTML = text;
      return txt.value;
}

const encodedEmail = ref('');
const encodedPhone = ref('');
const email = ref('');
const phone = ref('');

onMounted(() => {
      setTimeout(() => {
            encodedEmail.value = '&#99;&#111;&#110;&#116;&#97;&#99;&#116;&#64;&#114;&#101;&#115;&#101;&#97;&#117;&#45;&#99;&#110;&#99;&#46;&#102;&#114;';
            encodedPhone.value = '&#48;&#51; &#50;&#57; &#56;&#49; &#49;&#49; &#55;&#48;';
            email.value = decodeHTMLEntities(encodedEmail.value);
            phone.value = decodeHTMLEntities(encodedPhone.value);
      }, 1000);
});
</script>

<template>
  <a :href="`mailto:${email}`" class="lg:mt-4 hover:text-orange transition-all" v-html="encodedEmail" />
  <a :href="`tel:${phone}`" class="hover:text-orange transition-all" v-html="encodedPhone" />
</template>